/* Reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}
li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

/* Import Fonts */
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt");

/* Global Styles */
html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background: url(../imgs/acou_closeup.jpg) right bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

#wrapper {
  position: relative;
  width: 100%;
  /* margin: auto; */
}

.spacer {
  height: 360px;
}

.dummy {
  height: 368px;
}

.section_container {
  width: 80%;
  border: 0px;
  padding: 24px;
  margin: 24px auto;
}

/* Section Header */
#sectionheader {
  height: 36px;
}

/* Sections */
#sectiontitle {
  background: #ffffff;
}

#sectiontitle h1 {
  font-family: "Rock Salt", cursive;
  font-size: 48px;
}

#sectionbody {
  background: #ffffff;
}

/* Responsive Design */
@media screen and (max-width: 568px) {
  #header {
    padding: 20px 0;
  }

  .section_container {
    width: 90%;
    margin: 50px auto;
  }

  #sectiontitle h1 {
    font-size: 24px;
  }

  .spacer {
    height: 180px;
  }

  .dummy {
    height: 184px;
  }
}
