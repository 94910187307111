/* src/components/Navbar.css */
.menu-button {
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.nav-menu.open {
  transform: translateX(0);
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.nav-menu ul li {
  margin: 20px 0;
  text-align: center;
}

.nav-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-menu ul li a:hover {
  text-decoration: underline;
}
