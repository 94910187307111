#footer {
  width: 100%;
}

#legal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  padding: 6px 6px 6px 6px;
  margin: 0px 0;
  /* text-align: left; */
  font-size: 12px;
}
